import styles from './styles.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {isNumber} from 'lodash/lang';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';

import {Container, Col, Row} from 'reactstrap';

export default class ContentTitle extends React.Component {
  static defaultProps = {
    tag: 'h2',
    fluid: true,
    grid: true,
  };

  static propTypes = {
    tag: PropTypes.string,
    count: PropTypes.number,
  };

  get titleCount() {
    const {count} = this.props;

    return (
      <span
        className={classNames(styles['content-title__count'], 'notranslate')}
      >
        <NumberFormat
          displayType="text"
          value={count}
          thousandSeparator
          renderText={(value) => value}
        />
      </span>
    );
  }

  get title() {
    const {title, tag, count} = this.props;

    return React.createElement(
      tag,
      {className: styles['content-title__title']},
      <React.Fragment>
        {title}
        {isNumber(count) && this.titleCount}
      </React.Fragment>
    );
  }

  get desc() {
    const {description} = this.props;

    return React.createElement(
      'p',
      {className: styles['content-title__desc']},
      description
    );
  }

  render() {
    const {title, fluid, className, children, description, actions} =
      this.props;

    return (
      <div className={classNames(styles['content-title'], className)}>
        <Container fluid={fluid}>
          <Row className="align-items-center">
            <Col>
              {title && this.title}
              {description && this.desc}
            </Col>
            {actions && <Col md="auto">{actions}</Col>}
          </Row>
          {children}
        </Container>
      </div>
    );
  }
}
