import React from 'react';
import {Form} from 'reactstrap';
import serialize from 'form-serialize';
import {isEmpty} from 'lodash';

function FormSubmitValidate(props) {
  const {isLoading, onSubmit, objectKey, children} = props;

  async function handleSubmit(e) {
    e.preventDefault();

    const form = e.target;

    // form.checkValidity
    // Returns true if form contains valid data.
    const selects = form.getElementsByClassName('react-select--invalid');
    if (form.checkValidity() === false || !isEmpty(selects)) {
      form.classList.add('was-validated');
      return;
    }

    const formData = serialize(form, {hash: true, empty: false});
    const res = await onSubmit(formData);

    if (!isEmpty(res)) {
      form.classList.add('was-validated');
      const object = objectKey ? res[objectKey] : res;

      Object.keys(object).forEach((r) => {
        const message = object[r];
        const element = document.querySelector(`input[name='${r}']`);

        if (element) {
          element.setCustomValidity(message);
          const parentElement = element.parentElement;

          const invalidFeedback =
            parentElement.querySelector('.invalid-feedback');
          if (!!invalidFeedback) {
            invalidFeedback.innerHTML = message;
          }
        }
      });
    }
  }

  return (
    <Form key={isLoading} onSubmit={(e) => handleSubmit(e)} noValidate>
      {children}
    </Form>
  );
}

export default FormSubmitValidate;
