import styles from './styles.module.scss';

import React from 'react';
import {noop} from 'lodash/util';
import PropTypes from 'prop-types';
import {Row, Col, Button} from 'reactstrap';

import ContentLegend from './ContentLegend';

export default class ContentFieldsetHeader extends React.Component {
  static defaultProps = {
    editable: false,
    isEdit: false,
    onEdit: noop,
    onCancel: noop,
  };

  static propTypes = {
    title: PropTypes.any,
    description: PropTypes.any,
    editable: PropTypes.bool,
    isEdit: PropTypes.bool,
    onEdit: PropTypes.func,
    onCancel: PropTypes.func,
  };

  render() {
    const {title, description, editable, isEdit, onEdit, onCancel} = this.props;

    return (
      <Row className={styles['content-fieldset__header']}>
        <Col>
          <ContentLegend>{title}</ContentLegend>

          {description && (
            <p className={styles['content-fieldset__header__desc']}>
              {description}
            </p>
          )}
        </Col>

        {editable && (
          <Col xs="auto" className="text-right">
            {isEdit ? (
              <Button type="button" color="secondary" onClick={onCancel}>
                Cancel
              </Button>
            ) : (
              <Button type="button" color="dark" onClick={onEdit}>
                Edit
              </Button>
            )}
          </Col>
        )}
      </Row>
    );
  }
}
