import styles from './styles.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty, isArray, isString} from 'lodash/lang';
import {findIndex} from 'lodash/array';
import Dropzone from 'react-dropzone';

import {alertBase} from 'utils/alerts';
import {bytesToSize} from 'utils/numbers';

import {IconFA} from 'components/Icons';
import {Button, FormGroup, Label} from 'reactstrap';

class Component extends React.Component {
  static propTypes = {
    // 'image/*'
    // ['.txt','.pdf','.xlsx','.xls','.doc','.docx','.ppt','.pptx']
    accept: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    multiple: PropTypes.bool,
    maxSize: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    maxSize: 10,
    multiple: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      files: [],
    };

    this.handleDrop = this.handleDrop.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleDrop = async (acceptedFiles, rejectedFiles) => {
    if (!isEmpty(rejectedFiles)) {
      // Validate file size
      if (isArray(rejectedFiles)) {
        const {maxSize} = this.props;
        let hasBiggerSize = findIndex(rejectedFiles, (item) => {
          const {size} = item;
          return size > maxSize * (1024 * 1024);
        });

        if (hasBiggerSize >= 0) {
          await alertBase({
            type: 'error',
            text: `The maximum size for a single file is ${maxSize}MB.`,
          });

          return false;
        }
      }

      // Validate file format
      const {accept} = this.props;
      const formats = isArray(accept) ? accept.join(', ') : accept;

      await alertBase({
        type: 'error',
        text: `The following formats are supported: ${formats}`,
      });

      return false;
    }

    this.setState({files: acceptedFiles});
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {files} = this.state;

    const res = await this.props.onSubmit(files);
    if (res) this.props.callback();
  };

  get acceptFiles() {
    const {accept} = this.props;

    let formats;

    if (isEmpty(accept)) return false;
    if (isArray(accept)) formats = accept.join(', ');
    if (isString(accept)) formats = accept;

    return (
      <span>
        The following formats are supported: <code>{formats}</code>
      </span>
    );
  }

  render() {
    const {files} = this.state;
    const {maxSize, multiple, accept, isSubmitting} = this.props;

    return (
      <div>
        <FormGroup>
          <Label>Upload File</Label>
          <Dropzone
            ref={this.dropzone}
            className={styles['file-uploader']}
            onDrop={this.handleDrop}
            accept={accept}
            maxSize={maxSize * (1024 * 1024)}
            multiple={multiple}
            disabled={isSubmitting}
          >
            <div className={styles['file-uploader__message']}>
              <div>
                <p className={styles['message__main']}>
                  <IconFA name="upload" className="fa-lg" /> <br />
                  Drop a file here or click to upload.
                </p>
              </div>
            </div>
          </Dropzone>
        </FormGroup>

        <p className="font-size-sm text-muted">
          The maximum size for a single file is {maxSize}MB. <br />
          {this.acceptFiles && this.acceptFiles}
        </p>

        {files && (
          <FormGroup>
            {files.map((item, index) => {
              return (
                <p key={index}>
                  <strong>{item.name}</strong>{' '}
                  <span className="text-muted">({bytesToSize(item.size)})</span>
                </p>
              );
            })}
          </FormGroup>
        )}

        <FormGroup>
          <Button
            color="primary"
            onClick={this.handleSubmit}
            block
            disabled={isEmpty(files) || isSubmitting}
          >
            {isSubmitting ? 'Uploading File...' : 'Upload File'}
          </Button>
        </FormGroup>
      </div>
    );
  }
}

export default Component;
