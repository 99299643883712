import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {keys} from 'lodash/object';

import {FormGroup, Label, CustomInput, Input, FormFeedback} from 'reactstrap';

export default class ContentInput extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['text', 'select', 'textarea', 'password', 'email']),
  };

  static defaultProps = {
    type: 'text',
    isEdit: true,
    help: '',
    feedback: '',
  };

  get args() {
    const {type, label, id, name, placeholder, isEdit, ...rest} = this.props;

    return {
      id: id || name,
      type: type,
      name: name,
      placeholder: placeholder || label,
      bsSize: 'md',
      autoComplete: 'off',
      ...rest,
    };
  }

  get select() {
    const {options, placeholder} = this.props;

    return (
      <CustomInput {...this.args}>
        {placeholder && <option value="">{placeholder}</option>}

        {keys(options).map((optionKey, index) => {
          return (
            <option key={index} value={optionKey}>
              {options[optionKey]}
            </option>
          );
        })}
      </CustomInput>
    );
  }

  get formatted() {
    const {format, mask} = this.props;

    let newArgs = {
      format: format,
      mask: mask,
      ...this.args,
    };

    // Important to delete.
    delete newArgs['bsSize'];

    return <NumberFormat className="form-control" {...newArgs} />;
  }

  get input() {
    return <Input {...this.args} />;
  }

  get help() {
    const {help, isEdit} = this.props;

    if (help && isEdit) {
      return <div className="form-text font-size-sm text-muted">{help}</div>;
    }

    return null;
  }

  get feedback() {
    const {feedback} = this.props;

    if (feedback) {
      return <FormFeedback>{feedback}</FormFeedback>;
    } else {
      return <FormFeedback>{`This field is required.`}</FormFeedback>;
    }
  }

  render() {
    const {format, type, label, children} = this.props;

    let inputElement;

    if (format) {
      inputElement = this.formatted;
    } else if (!format && type === 'select') {
      inputElement = this.select;
    } else {
      inputElement = this.input;
    }

    return (
      <FormGroup>
        <Label>{label}</Label>

        {inputElement}
        {this.help}
        {this.feedback}

        {children && children}
      </FormGroup>
    );
  }
}
