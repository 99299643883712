import React from 'react';
import CookieConsentBanner from './CookieConsentBanner';
import CookieConsentModal from './CookieConsentModal';

function CookieConsent(props) {
  const {isOpen, showBanner, isBannerVisible, toggleOpen, onAcceptCookies} =
    props;

  return (
    <React.Fragment>
      {isBannerVisible && (
        <CookieConsentBanner
          showBanner={showBanner}
          toggleOpen={toggleOpen}
          onAcceptCookies={onAcceptCookies}
        />
      )}
      <CookieConsentModal
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        onAcceptCookies={onAcceptCookies}
      />
    </React.Fragment>
  );
}

export default CookieConsent;
