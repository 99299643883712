import styles from './styles.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {ContentFieldsetHeader} from 'components/Contents';

export default class ContentFieldset extends React.Component {
  static defaultProps = {
    tag: 'fieldset',
  };

  static propTypes = {
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  };

  render() {
    const {
      tag,
      className,
      title,
      description,
      editable,
      isEdit,
      onEdit,
      onCancel,
      children,
      ...rest
    } = this.props;

    return React.createElement(
      tag,
      {
        className: classNames(styles['content-fieldset'], className),
        ...rest,
      },
      <React.Fragment>
        {title && (
          <ContentFieldsetHeader
            title={title}
            description={description}
            editable={editable}
            isEdit={isEdit}
            onEdit={onEdit}
            onCancel={onCancel}
          />
        )}

        {children}
      </React.Fragment>
    );
  }
}
