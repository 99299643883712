import styles from './styles.module.scss';

import React from 'react';
import {isBoolean} from 'lodash/lang';
import {Button, Col, Row} from 'reactstrap';

export default class Component extends React.Component {
  static defaultProps = {
    disabled: false,
    isEdit: undefined,
  };

  render() {
    const {disabled, title, description, isEdit, isSubmitting, toggleEdit} =
      this.props;

    return (
      <div className="mb-4">
        <Row className="align-items-center">
          <Col>
            {title && <legend className={styles['form-title']}>{title}</legend>}
          </Col>

          {!disabled && isBoolean(isEdit) && (
            <Col xs="auto">
              <Button
                type="button"
                color={!!isEdit ? 'secondary' : 'dark'}
                onClick={toggleEdit}
                disabled={!!isSubmitting}
              >
                {isEdit ? 'Cancel' : 'Edit'}
              </Button>
            </Col>
          )}
        </Row>

        {description && <p className="text-muted mt-2 mb-0">{description}</p>}
      </div>
    );
  }
}
