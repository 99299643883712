import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {COMPANY_TYPES} from 'configs';

import {date} from 'utils/dates';
import {alertBase} from 'utils/alerts';
import {
  sendBranchActivation,
  sendCertificationBodyActivation,
  getActivationHistory,
} from 'api/auth';

import {ContentTable} from 'components/Contents';
import {BaseButton} from 'components/Buttons';
import {FormField, FormFieldset} from 'components/Forms';
import FormActivateActivationLink from './FormActivateActivationLink';

const propTypes = {
  email: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  companyType: PropTypes.string.isRequired,
};

const defaultProps = {};

function Component({email, companyId, companyType}) {
  const [data, setData] = useState([]);
  const [isRetrieving, setIsRetrieving] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    getHistory();
  }, [false]);

  async function getHistory() {
    await setIsRetrieving(true);
    const {data} = await getActivationHistory(companyType, companyId, {
      limit: 5,
    });
    await setData(data);
    await setIsRetrieving(false);
  }

  async function sendActivation(e) {
    e.preventDefault();

    if (!email) {
      await alertBase({
        type: 'error',
        title: 'Email address is required',
        text: 'Email address is required to send activation link. Please provide email address.',
      });

      return false;
    }

    await setIsSending(true);

    if (companyType === COMPANY_TYPES.cb) {
      await sendCertificationBodyActivation(companyId); // Send CB Activation
    } else {
      await sendBranchActivation(companyId); // Send Account (Branch Company) Activation
    }

    await alertBase({
      text: 'Activation email has been sent to the account email.',
    });

    await setIsSending(false);
    await getHistory();
  }

  return (
    <FormFieldset>
      <FormField row label="Activation Email">
        <BaseButton
          icon="paper-plane-o"
          color="primary"
          text={isSending ? 'Sending...' : 'Send Activation Email'}
          outline
          onClick={sendActivation}
          disabled={isSending}
        />
      </FormField>

      <FormActivateActivationLink companyId={companyId} />

      <FormField row label="Email History" className="mb-0">
        <ContentTable
          head={['Status', 'Send date']}
          size="sm"
          minHeight={100}
          isFetching={isRetrieving}
          className="mb-0"
        >
          {data.map((item, index) => {
            return (
              <tr key={index}>
                <th>Sent Activation Email</th>
                <td>{date(item.created_at, 'YYYY-MM-DD HH:mm:ss')}</td>
              </tr>
            );
          })}
        </ContentTable>
      </FormField>
    </FormFieldset>
  );
}
Component.propTypes = propTypes;
Component.defaultProps = defaultProps;
export default Component;
