import styles from './styles.module.scss';

import React from 'react';
import classNames from 'classnames';

const defaultProp = {
  tag: 'div',
  isLoading: false,
};

function ContentBoxTitle({tag, className, children}) {
  const _className = classNames(styles['content-box__title'], {
    [className]: !!className,
  });

  return React.createElement(tag, {className: _className}, children);
}

ContentBoxTitle.defaultProps = defaultProp;

export default ContentBoxTitle;
