import styles from './styles.module.scss';
import React from 'react';
import classNames from 'classnames';

function FormSection(props) {
  const {className, children, ...rest} = props;

  return (
    <section
      className={classNames(styles['form-section'], {
        [className]: !!className,
      })}
      {...rest}
    >
      {children}
    </section>
  );
}

export default FormSection;
