import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';
import {IconFA} from 'components/Icons';
import extend from 'lodash/extend';
import equal from 'deep-equal';
import {queryParse, queryStringify} from 'utils/http';

class ContentPagination extends React.Component {
  static defaultProps = {
    className: 'justify-content-end',
  };

  static propTypes = {
    totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);

    const {search} = props.location;

    this.state = {
      offset: this.getOffset(search),
    };

    this.buildHref = this.buildHref.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {search} = this.props.location;

    if (!equal(prevProps.location.search, search)) {
      this.setState({
        offset: this.getOffset(search),
      });
    }
  }

  getOffset(search) {
    const query = queryParse(search);

    return Number(query.offset) || 0;
  }

  buildHref(page) {
    const {location} = this.props;

    // Assign offset to exist query
    const query = extend(queryParse(location.search), {
      offset: page - 1,
    });

    return `${location.pathname}?${queryStringify(query)}`;
  }

  handleChange({selected}) {
    const {location, history} = this.props;

    // Assign offset to exist query
    const query = extend(queryParse(location.search), {
      offset: selected,
    });

    history.push({
      pathname: location.pathname,
      search: queryStringify(query),
    });
  }

  render() {
    const {offset} = this.state;
    const {totalPages, className} = this.props;

    return (
      <ReactPaginate
        pageCount={Number(totalPages) || 0}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        forcePage={Number(offset)}
        activeClassName={'active'}
        containerClassName={classNames('pagination mt-3', className)}
        onPageChange={this.handleChange}
        hrefBuilder={this.buildHref}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        nextLabel={<IconFA name="angle-right" label="Next" />}
        previousClassName="page-item"
        previousLinkClassName="page-link"
        previousLabel={<IconFA name="angle-left" label="Previous" />}
        breakClassName="page-item"
        breakLabel={<span className="page-link">...</span>}
      />
    );
  }
}

export default withRouter(ContentPagination);
