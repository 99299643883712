import React from 'react';
import cx from 'classnames';
import Autosuggest from 'react-autosuggest';
import {isEqual} from 'lodash/lang';
import {debounce} from 'throttle-debounce';

class SearchInput extends React.Component {
  _isMounted = false;

  static defaultProps = {
    name: '',
    placeholder: 'Search',
    size: 'md',
    autoFocus: false,
    value: '',
    minLength: 2,
    onChange: () => {},
    onInputChange: () => {},
    onSuggestionSelected: () => {},
    request: () => {},
    getOptionLabel: () => {},
    getOptionValue: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      suggestions: [],
      isLoading: false,
    };

    this.loadDebounced = debounce(500, this.load);
  }

  componentDidMount() {
    this.load();
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.value, this.props.value)) {
      this.setState({value: nextProps.value});
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load = async (value) => {
    try {
      if (!this._isMounted) return false;

      await this.setState({isLoading: true});
      const response = await this.props.request(value);

      if (!this._isMounted) return false;

      this.setState({suggestions: response.data, isLoading: false});
    } catch (err) {
      if (!this._isMounted) return false;

      this.setState({isLoading: false});
    }
  };

  onSuggestionsClearRequested = () => {
    if (!this._isMounted) return false;

    this.setState({
      suggestions: [],
    });
  };

  onSuggestionsFetchRequested = (option) => this.loadDebounced(option.value);

  renderSuggestion = (suggestion) => this.props.getOptionLabel(suggestion);

  getSuggestionValue = (suggestion) => this.props.getOptionValue(suggestion);

  onInputChange = (event, {newValue}) => {
    if (!this._isMounted) return;

    this.setState({
      value: newValue,
    });
  };

  onSuggestionSelected = (event, value) => {
    return this.props.onSuggestionSelected(value.suggestion);
  };

  render() {
    const inputProps = {
      name: this.props.name,
      value: this.state.value,
      onChange: this.onInputChange,
      placeholder: this.props.placeholder,
      className: cx('form-control form-control-search', {
        'form-control-sm': this.props.size === 'sm',
        'form-control-lg': this.props.size === 'lg',
        [this.props.className]: !!this.props.className,
      }),
      autoFocus: this.props.autoFocus,
      minLength: this.props.minLength,
      maxLength: '200',
    };

    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        focusInputOnSuggestionClick={false}
        highlightFirstSuggestion={false}
        inputProps={inputProps}
        onSuggestionSelected={this.onSuggestionSelected}
      />
    );
  }
}

export default SearchInput;
