import React, {useEffect} from 'react';

const GoogleTranslateElement = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false,
        layout:
          window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
      },
      'google_translate_element'
    );
  };

  useEffect(() => {
    const addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <div
      className="py-1 px-3 text-right"
      style={{
        height: 36,
        borderBottom: '1px solid #e8e8e8',
        background: '#fff',
      }}
    >
      <div id="google_translate_element" />
    </div>
  );
};

export default GoogleTranslateElement;
