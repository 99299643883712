import React, {useEffect, useState} from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormText,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import ReactAvatarEditor from 'react-avatar-editor';
import {get, isEmpty} from 'lodash';

import {fileToImageUrl, dataURItoBlob} from 'utils/images';
import {alertByError, alertError, alertConfirm} from 'utils/alerts';
import {validateImage, IMAGES_FEEDBACK, ONLY_ACCEPTED_IMAGE} from 'configs';
import {returnFileSize} from 'utils/images';

import {Row, Col, FormGroup, Label, Input, CustomInput} from 'reactstrap';
import {BaseButton} from 'components/Buttons';
import {IconFA} from 'components/Icons';

function Component({
  title,
  isOpen,
  toggleOpen,
  isLoading,
  defaultImage,
  fileSizeLimit = 5,
  //allowZoomOut,
  isSubmitting,
  onSubmit,

  deleteConfirmText = 'Are you sure you want to delete this photo?',
  deleteText = 'Delete photo',
  callback,
}) {
  const onlyAcceptedImage = ONLY_ACCEPTED_IMAGE.all;

  const supportedImages = Object.keys(ONLY_ACCEPTED_IMAGE).map((v, key) =>
    v === 'image' || v === 'all'
      ? ''
      : ' ' + v.toUpperCase() + (key === 3 ? ' &' : key === 4 ? '' : ', ')
  );

  const [editor, setEditor] = useState({});
  const [image, setImage] = useState(
    defaultImage ? fileToImageUrl(defaultImage) : ''
  );
  const [position, setPosition] = useState({x: 0.5, y: 0.5});
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [border] = useState(10);
  const [width] = useState(160);
  const [height] = useState(160);
  const [borderRadius] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setImage(fileToImageUrl(defaultImage) || '');
    }
  }, [isOpen, defaultImage]);

  function handleScale(e) {
    setScale(parseFloat(e.target.value));
  }

  function handleRotateLeft(e) {
    e.preventDefault();
    setRotate(rotate - 90);
  }

  function handleRotateRight(e) {
    e.preventDefault();
    setRotate(rotate + 90);
  }

  function handlePositionChange(position) {
    setPosition(position);
  }

  function handleDrop(acceptedFiles) {
    setImage(acceptedFiles[0]);
  }

  function handleRef(editor) {
    if (editor) setEditor(editor);
  }

  async function handleSave(e) {
    e.preventDefault();

    if (!image && isEmpty(defaultImage) && !fileToImageUrl(defaultImage)) {
      await alertError({
        text: `Please upload a image to save.`,
      });

      return;
    }

    const fileSize = returnFileSize(image.size);

    if (fileSizeLimit && fileSizeLimit <= fileSize) {
      await alertError({
        text: `Cannot send file size that is greater than ${fileSizeLimit} MB`,
      });
      return;
    }

    // Original file name from image.
    const imageName = get(image, 'name', 'image.png');

    // Get Data URL and convert to PNG.
    const dataURL = editor.getImageScaledToCanvas().toDataURL('image/png');
    const blob = dataURItoBlob(dataURL);

    let formData = new FormData();
    formData.append('image', blob, imageName);

    try {
      const res = await onSubmit(image ? formData : false);
      if (callback) callback(res);
    } catch (e) {
      await alertByError(e);
    }
  }

  async function handleNewImage(e) {
    const target = e.target;
    const image = target.files[0];
    const isValid = image ? validateImage(image.type) : false;

    if (isValid) {
      setImage(image);
    } else {
      const result = Object.keys(ONLY_ACCEPTED_IMAGE).find(
        (key) => ONLY_ACCEPTED_IMAGE[key] === onlyAcceptedImage
      );

      await alertError({
        title: 'Invalid File Type',
        text: IMAGES_FEEDBACK[result],
      });

      target.value = ''; // Reset File Input
    }
  }

  async function handleDelete() {
    const {value} = await alertConfirm({text: deleteConfirmText});
    if (value) setImage('');
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleOpen} centered>
      <ModalHeader toggle={toggleOpen}>{title || 'Image Upload'}</ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12" md="auto">
            <Dropzone
              onDrop={handleDrop}
              accept="image/*"
              disableClick
              style={{
                width: `${width + border * 2}px`,
                height: `${height + border * 2}px`,
              }}
            >
              <ReactAvatarEditor
                className="editor-canvas"
                crossOrigin="anonymous"
                ref={handleRef}
                image={image}
                border={border}
                scale={scale}
                width={width}
                height={height}
                position={position}
                onPositionChange={handlePositionChange}
                rotate={rotate}
                borderRadius={width / (100 / borderRadius)}
              />
            </Dropzone>

            {deleteText && fileToImageUrl(defaultImage, 'origin') && (
              <Button
                outline
                color="white"
                className="text-danger d-block mx-auto mt-3"
                onClick={() => handleDelete()}
              >
                {deleteText}
              </Button>
            )}
          </Col>
          <Col>
            <fieldset key={isLoading} disabled={isSubmitting}>
              <FormGroup>
                <Input
                  id="file"
                  type="file"
                  name="file"
                  accept="image/*"
                  onChange={handleNewImage}
                />
                <FormText>
                  <p className="mb-0">
                    Supported file types:
                    {supportedImages}
                  </p>
                  <p className="mb-0">Max file size: {fileSizeLimit}MB</p>
                </FormText>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="zoom" sm={12} md={3}>
                  Zoom
                </Label>
                <Col>
                  <CustomInput
                    id="zoom"
                    type="range"
                    onChange={handleScale}
                    min="0.1"
                    max="2"
                    step="0.1"
                    defaultValue="1"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={12} md={3}>
                  Rotate
                </Label>

                <Col>
                  <Row noGutters>
                    <Col className="mr-1">
                      <BaseButton
                        color="primary"
                        outline
                        block
                        onClick={handleRotateLeft}
                      >
                        <IconFA name="rotate-left" /> Left
                      </BaseButton>
                    </Col>
                    <Col className="ml-1">
                      <BaseButton
                        color="primary"
                        outline
                        block
                        onClick={handleRotateRight}
                      >
                        <IconFA name="rotate-right" /> Right
                      </BaseButton>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            </fieldset>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline className="mx-2" onClick={toggleOpen}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave} disabled={isSubmitting}>
          {isSubmitting ? 'Saving...' : 'Save changes'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default Component;
