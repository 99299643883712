import React from 'react';
import {isString} from 'lodash/lang';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import truncate from 'voca/truncate';

const propTypes = {
  limit: PropTypes.number,
  to: PropTypes.string,
};
const defaultProps = {
  limit: 50,
};

function ContentTableText(props) {
  const {to, children, limit, ...rest} = props;

  const elementType = to ? Link : React.Fragment;
  const elementProps = to ? {to: to, title: children} : {...rest};
  const elementChildren = isString(children)
    ? truncate(children || '', limit)
    : children;

  return React.createElement(elementType, elementProps, elementChildren);
}

ContentTableText.propTypes = propTypes;
ContentTableText.defaultProps = defaultProps;

export default ContentTableText;
