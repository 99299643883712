import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import cx from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.number),
};
const defaultProps = {
  value: 20,
  options: [20, 50, 100],
};

function ContentTableLimit(props) {
  const {value, total, onChange, options, className} = props;
  const handleChangeLimit = (limit) => {
    onChange(limit);
  };

  return (
    <div className={cx('text-muted', className)}>
      <span className="mr-1">Show</span>
      <UncontrolledDropdown className="d-inline">
        <DropdownToggle caret size="sm" color="primary" outline>
          {value}
        </DropdownToggle>
        <DropdownMenu>
          {options.map((limit) => (
            <DropdownItem
              key={limit}
              size="sm"
              onClick={() => handleChangeLimit(limit)}
              children={limit}
            ></DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <span className="ml-1">of {total}</span>
    </div>
  );
}

ContentTableLimit.propTypes = propTypes;
ContentTableLimit.defaultProps = defaultProps;
export default ContentTableLimit;
