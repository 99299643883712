import {first, last, truncate} from 'lodash';
import React, {useState} from 'react';
import {matchPath, withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import styles from './styles.module.scss';

function bakeBread(pathname, tree) {
  let items = [];

  tree.forEach((item) => {
    const isMatch = matchPath(pathname, {
      exact: false,
      path: item.match,
    });

    if (isMatch) {
      items.push({
        active: isMatch.isExact,
        to: item.to,
        text: item.text,
      });

      if (item.routes) {
        items = items.concat(bakeBread(pathname, item.routes));
      }
    }
  });

  return items;
}

function ContentBreadcrumb(props) {
  const {location, tree} = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const breadcrumbs = bakeBread(location.pathname, tree);

  const items = breadcrumbs.map((item, index) => {
    if (item) {
      const {to, text, active} = item;
      const trimmed = truncate(text, 30);

      return (
        <BreadcrumbItem key={index} active={active}>
          {to && !active ? <Link to={to}>{trimmed}</Link> : trimmed}
        </BreadcrumbItem>
      );
    }

    return null;
  });

  function handleExpand() {
    setIsExpanded((prev) => !prev);
  }

  if (items.length > 3 && !isExpanded) {
    return (
      <Breadcrumb className={styles['content-breadcrumb']}>
        {first(items)}
        <BreadcrumbItem
          active={false}
          className="text-primary cursor-pointer"
          onClick={handleExpand}
        >
          ...
        </BreadcrumbItem>
        {last(items)}
      </Breadcrumb>
    );
  }

  return (
    <Breadcrumb className={styles['content-breadcrumb']}>{items}</Breadcrumb>
  );
}

export default withRouter(ContentBreadcrumb);
