import styles from './styles.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class ContentLegend extends React.Component {
  static defaultProps = {
    tag: 'legend',
  };

  static propTypes = {
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  };

  render() {
    const {tag, className, children} = this.props;

    return React.createElement(
      tag,
      {className: classNames(styles['content-legend'], className)},
      children
    );
  }
}
