import React from 'react';
import {FormText} from 'reactstrap';

const FormHelp = ({children}) => {
  return (
    <FormText tag="span" className="font-size-sm">
      {children}
    </FormText>
  );
};

export default FormHelp;
