import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import {isEmpty} from 'lodash';

const styles = {
  control: (styles, {isDisabled}) => ({
    ...styles,
    borderRadius: 0,
    borderColor: '#ced4da',
    backgroundColor: isDisabled ? '#e9ecef' : 'white',
  }),
  option: (styles) => ({...styles, borderRadius: 0}),
  input: (styles) => styles,
  placeholder: (styles) => styles,
  singleValue: (styles) => styles,
  menu: (styles) => ({...styles, zIndex: 5}),
};

const SelectBase = (props) => {
  const {
    className,
    defaultValue,
    onChange,
    required = false,
    isDisabled = false,
    ...rest
  } = props;
  // Setting for validation
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const _className = cx(className, {
    'react-select--valid':
      required && !isDisabled ? !isEmpty(selectedValue) : !isDisabled,
    'react-select--invalid':
      required && !isDisabled ? isEmpty(selectedValue) : false,
  });

  function handleChange(selected) {
    setSelectedValue(selected);
    if (onChange) onChange(selected);
  }

  return (
    <Select
      value={selectedValue}
      styles={styles}
      className={_className}
      onChange={handleChange}
      required={required}
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      {...rest}
    />
  );
};

SelectBase.defaultProps = {
  className: 'react-select-container',
  classNamePrefix: 'react-select',
};

const SelectSearchBase = (props) => {
  return <Select styles={styles} {...props} />;
};

SelectSearchBase.defaultProps = {
  components: {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  },
};

const SelectAsyncBase = (props) => {
  const {
    className,
    defaultValue,
    onChange,
    required,
    isDisabled = false,
    reloadDependency,
    ...rest
  } = props;
  // Setting for validation
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const [forceUpdateKey, setForceUpdateKey] = useState(0);

  // will trigger rerender to reload options when dependency changed
  useEffect(() => {
    if (!!reloadDependency) {
      setForceUpdateKey((prevKey) => prevKey + 1);
    }
  }, [reloadDependency]);

  const _className = cx(className, {
    'react-select--valid':
      required && !isDisabled ? !isEmpty(selectedValue) : !isDisabled,
    'react-select--invalid':
      required && !isDisabled ? isEmpty(selectedValue) : false,
  });

  function handleChange(selected) {
    setSelectedValue(selected);
    if (onChange) onChange(selected);
  }

  return (
    <AsyncSelect
      key={forceUpdateKey}
      className={_className}
      onChange={handleChange}
      styles={styles}
      required={required}
      isDisabled={isDisabled}
      {...rest}
    />
  );
};

SelectAsyncBase.defaultProps = {
  className: 'react-select-container',
  classNamePrefix: 'react-select',
};

const SelectSearchAsyncBase = (props) => {
  return <AsyncSelect styles={styles} {...props} />;
};

SelectSearchAsyncBase.defaultProps = {
  components: {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  },
};

export {SelectBase, SelectSearchBase, SelectAsyncBase, SelectSearchAsyncBase};
