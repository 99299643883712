import React from 'react';
import PropTypes from 'prop-types';
import {noop} from 'lodash/util';
import {isFunction} from 'lodash/lang';
import {FormGroup, Button} from 'reactstrap';
import {alertBase, alertByError} from 'utils/alerts';
import FormBase from './FormBase';
import FormHeader from './FormHeader';
import FormFieldset from './FormFieldset';

export default class FormUpdate extends React.Component {
  static propTypes = {
    submit: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    callback: PropTypes.func,
    isSubmitting: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    submit: true,
    header: true,
    fieldset: true,
    disabled: false,
    callback: noop,
  };

  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSubmit = async (data) => {
    try {
      await this.props.onSubmit(data);
      await alertBase({text: 'Your changes have been saved.'});
      if (!this._isMounted) return false;
      await this.setState({isEdit: false});

      this.props.callback();
    } catch (e) {
      await alertByError(e);
    }
  };

  toggleEdit() {
    if (!this._isMounted) return false;

    this.setState((prevState) => {
      return {
        isEdit: !prevState.isEdit,
      };
    });
  }

  get header() {
    const {isEdit} = this.state;
    const {disabled, header, title, description, isSubmitting} = this.props;

    if (!header) return null;

    return (
      <FormHeader
        title={title}
        description={description}
        isSubmitting={isSubmitting}
        isEdit={isEdit}
        toggleEdit={this.toggleEdit}
        disabled={disabled}
      />
    );
  }

  get fieldset() {
    const {isEdit} = this.state;
    const {fieldset, children} = this.props;

    if (!fieldset)
      return isFunction(children) ? children({isEdit: isEdit}) : children;

    return (
      <FormFieldset disabled={!isEdit}>
        {isFunction(children) ? children({isEdit: isEdit}) : children}
      </FormFieldset>
    );
  }

  get submit() {
    const {isEdit} = this.state;
    const {submit, isSubmitting} = this.props;

    if (!submit) return null;

    return isEdit ? (
      <FormGroup className="mt-4">
        <Button
          type="submit"
          color="primary"
          size="lg"
          className="mr-2"
          block
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save changes'}
        </Button>
      </FormGroup>
    ) : null;
  }

  render() {
    const {serializeOptions} = this.props;

    return (
      <FormBase
        onSubmit={this.handleSubmit}
        serializeOptions={serializeOptions}
      >
        {this.header}
        {this.fieldset}

        {this.state.submit && <hr />}

        {this.submit}
      </FormBase>
    );
  }
}
