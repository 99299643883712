import React, {useState} from 'react';
import cx from 'classnames';

import {
  Label,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
} from 'reactstrap';
import {IconFA} from 'components/Icons';

FormFieldPassword.defaultProps = {
  name: 'password',
  type: 'password',
  autoComplete: 'off',
};

function FormFieldPassword(props) {
  const {
    type,
    children,
    className,
    onChange,
    row,
    label,
    name,
    disabled,
    ...rest
  } = props;

  const [show, setShow] = useState(false);

  function handleChange(e) {
    const target = e.target;
    const inputGroup = target.closest('.input-group');

    if (target.checkValidity()) {
      inputGroup.classList.remove('is-invalid');
    } else {
      inputGroup.classList.add('is-invalid');
    }

    if (onChange) onChange(e);
  }

  function handleShow(e) {
    e.preventDefault();
    setShow(!show);
  }

  const columnLabel = row ? {lg: 3} : {};
  const columnInput = row ? {lg: 9, xl: 6} : {};

  return (
    <FormGroup row={row}>
      {label && (
        <Label {...columnLabel} htmlFor={name}>
          {label}
        </Label>
      )}

      {React.createElement(
        row ? Col : 'div',
        {...columnInput},
        <InputGroup className={cx('input-group-merge', className)}>
          <Input
            name={name}
            type={show ? 'text' : type}
            onChange={handleChange}
            disabled={disabled}
            {...rest}
          />

          <InputGroupAddon addonType="append">
            <InputGroupText
              className={cx('btn', {
                'bg-white': !disabled,
              })}
              tag="button"
              onClick={handleShow}
              disabled={disabled}
            >
              <IconFA name={show ? 'eye-slash' : 'eye'} />
            </InputGroupText>
          </InputGroupAddon>

          {children}
        </InputGroup>
      )}
    </FormGroup>
  );
}

export default FormFieldPassword;
