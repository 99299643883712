import React from 'react';
import AsyncSelect from 'react-select/async';
import {IconFA} from 'components/Icons';

const disabledStyles = {
  control: (styles) => ({
    ...styles,
    color: '#6c757d',
    borderRadius: 0,
    borderColor: '#ced4da',
    backgroundColor: '#e9ecef',
  }),
  option: (styles) => ({...styles, borderRadius: 0}),
  input: (styles) => styles,
  placeholder: (styles) => styles,
  singleValue: (styles) => styles,
};

const enabledStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: 0,
    borderColor: '#ced4da',
    backgroundColor: 'white',
  }),
  option: (styles) => ({...styles, color: '#000', borderRadius: 0}),
  input: (styles) => styles,
  placeholder: (styles) => styles,
  singleValue: (styles) => styles,
};

const defaultProps = {
  components: {
    DropdownIndicator: () => {
      return <IconFA name="search" className="mr-2 text-secondary" />;
    },
    IndicatorSeparator: () => null,
  },
};

function SearchAsyncBase(props) {
  const {isDisabled, ...rest} = props;

  return (
    <AsyncSelect
      isDisabled={isDisabled}
      styles={isDisabled ? disabledStyles : enabledStyles}
      {...rest}
    />
  );
}

SearchAsyncBase.defaultProps = defaultProps;

export default SearchAsyncBase;
