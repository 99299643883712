import React from 'react';
import PropTypes from 'prop-types';

import {uploadImage} from 'api/uploads';
import {FormGroup, FormText, Progress, Button} from 'reactstrap';

class Component extends React.Component {
  static propTypes = {
    onUploadComplete: PropTypes.func,
    onUploadProgress: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      progress: 0,
      isFetching: false,
      value: '',
    };

    this.upload = this.upload.bind(this);
    this.uploadProgress = this.uploadProgress.bind(this);
  }

  upload(e) {
    e.preventDefault();

    const file = e.target.files[0];

    if (!file) return false;

    let formData = new FormData();
    formData.append('image', file);

    uploadImage(formData, this.uploadProgress).then(({data}) => {
      const image = JSON.stringify(data);

      this.props.onUploadComplete(image);

      this.setState({
        progress: 0,
        value: image,
      });
    });
  }

  uploadProgress(progressEvent) {
    const progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    this.setState({
      progress: progress,
    });
  }

  render() {
    const {progress, value} = this.state;
    const {className, required, accept, disabled, name} = this.props;

    return (
      <FormGroup className="mb-0">
        <Button
          color="dark"
          onClick={() => this.refs.input.click()}
          disabled={disabled}
        >
          {progress > 0 || disabled ? 'Uploading..' : 'Upload'}
        </Button>

        <input
          ref="input"
          type="file"
          onChange={this.upload}
          className={className}
          required={required}
          accept={accept}
          style={{display: 'none'}}
          disabled={disabled}
        />

        <input type="hidden" name={name} value={value || ''} />

        <FormText>Maximum size allowed is 500kb of PNG and JPG</FormText>

        {progress > 0 && (
          <Progress striped animated color="info" value={progress} />
        )}
      </FormGroup>
    );
  }
}

export default Component;
