import styles from './styles.module.scss';

import React from 'react';
import classNames from 'classnames';

export default class ContentForm extends React.Component {
  render() {
    const {className, children, ...rest} = this.props;

    return (
      <div
        className={classNames(styles['content-form-wrapper'], className)}
        {...rest}
      >
        {children}
      </div>
    );
  }
}
