import React from 'react';
import urlJoin from 'url-join';
import PropTypes from 'prop-types';

import {COMPANY_ACTIVATE_URL} from 'configs';
import {copyToClipboard} from 'utils/documents';

import {InputGroup, Input, InputGroupAddon, Button} from 'reactstrap';

import {IconFA} from 'components/Icons';
import {FormField} from 'components/Forms';

const propTypes = {
  companyId: PropTypes.string.isRequired,
};

const defaultProps = {};

function FormActivateActivationLink({companyId}) {
  let referralLink;

  return (
    <FormField row label="Activation Link">
      <InputGroup>
        <Input
          type="text"
          innerRef={(node) => (referralLink = node)}
          defaultValue={urlJoin(COMPANY_ACTIVATE_URL, companyId)}
          readOnly
        />
        <InputGroupAddon addonType="append">
          <Button color="primary" onClick={() => copyToClipboard(referralLink)}>
            <IconFA name="files-o" /> Copy link
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </FormField>
  );
}
FormActivateActivationLink.propTypes = propTypes;
FormActivateActivationLink.defaultProps = defaultProps;

export default FormActivateActivationLink;
