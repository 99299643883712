import styles from './styles.module.scss';

import React from 'react';
import classNames from 'classnames';
import {FormField} from 'components/Forms';

const defaultProps = {
  width: '250px',
};

class ContentFilters extends React.Component {
  render() {
    const {width, children, className} = this.props;

    return (
      <div
        className={classNames(styles['content-filters'], {
          [className]: !!className,
        })}
      >
        {Array.isArray(children) ? (
          children.map((child, index) => {
            if (child) {
              return (
                <FormField
                  key={index}
                  className={styles['content-filters__item']}
                  style={{width: width}}
                >
                  {child}
                </FormField>
              );
            }

            return null;
          })
        ) : (
          <FormField
            className={styles['content-filters__item']}
            style={{width: width}}
          >
            {children}
          </FormField>
        )}
      </div>
    );
  }
}

ContentFilters.defaultProps = defaultProps;

export default ContentFilters;
