import React from 'react';
import PropTypes from 'prop-types';

export default class Component extends React.Component {
  static propTypes = {
    disabled: PropTypes.oneOf([undefined, true, false]),
  };

  render() {
    const {disabled, ...rest} = this.props;

    return <fieldset key={disabled} disabled={disabled} {...rest} />;
  }
}
