import React, {useState, useEffect} from 'react';
import Dropzone from 'react-dropzone';
import ReactAvatarEditor from 'react-avatar-editor';
import {get} from 'lodash/object';

import {fileToImageUrl, dataURItoBlob} from 'utils/images';
import {alertByError} from 'utils/alerts';

import {Row, Col, FormGroup, Label, Input, CustomInput} from 'reactstrap';
import {BaseButton} from 'components/Buttons';
import {IconFA} from 'components/Icons';

function Component({
  isLoading,
  defaultImage,
  //allowZoomOut,
  isSubmitting,
  onSubmit,
  callback,
  saveBtnProps,
  leftBtnProps,
  rightBtnProps,
}) {
  const [editor, setEditor] = useState({});
  const [image, setImage] = useState(
    defaultImage ? fileToImageUrl(defaultImage) : ''
  );
  const [position, setPosition] = useState({x: 0.5, y: 0.5});
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [border] = useState(10);
  const [width] = useState(160);
  const [height] = useState(160);
  const [borderRadius] = useState(0);

  useEffect(() => {
    setImage(fileToImageUrl(defaultImage) || '');
  }, [defaultImage]);

  function handleNewImage(e) {
    setImage(e.target.files[0]);
  }

  function handleScale(e) {
    setScale(parseFloat(e.target.value));
  }

  function handleRotateLeft(e) {
    e.preventDefault();
    setRotate(rotate - 90);
  }

  function handleRotateRight(e) {
    e.preventDefault();
    setRotate(rotate + 90);
  }

  function handlePositionChange(position) {
    setPosition(position);
  }

  function handleDrop(acceptedFiles) {
    setImage(acceptedFiles[0]);
  }

  function handleRef(editor) {
    if (editor) setEditor(editor);
  }

  async function handleSave(e) {
    e.preventDefault();

    // Original file name from image.
    const imageName = get(image, 'name', 'image.png');

    // Get Data URL and convert to PNG.
    const dataURL = editor.getImageScaledToCanvas().toDataURL('image/png');
    const blob = dataURItoBlob(dataURL);

    let formData = new FormData();
    formData.append('image', blob, imageName);

    try {
      const res = await onSubmit(formData);
      callback(res);
    } catch (e) {
      await alertByError(e);
    }
  }

  return (
    <Row>
      <Col sm="12" md="auto">
        <Dropzone
          onDrop={handleDrop}
          accept="image/*"
          disableClick
          style={{
            width: `${width + border * 2}px`,
            height: `${height + border * 2}px`,
          }}
        >
          <ReactAvatarEditor
            className="editor-canvas"
            crossOrigin="anonymous"
            ref={handleRef}
            image={image}
            border={border}
            scale={scale}
            width={width}
            height={height}
            position={position}
            onPositionChange={handlePositionChange}
            rotate={rotate}
            borderRadius={width / (100 / borderRadius)}
          />
        </Dropzone>
      </Col>
      <Col>
        <fieldset key={isLoading} disabled={isSubmitting}>
          <FormGroup>
            <Input
              id="file"
              type="file"
              name="file"
              accept="image/*"
              onChange={handleNewImage}
            />
          </FormGroup>
          <FormGroup row className="align-items-center">
            <Label for="zoom" sm={12} md={3}>
              Zoom
            </Label>
            <Col>
              <CustomInput
                id="zoom"
                type="range"
                onChange={handleScale}
                min="0.1"
                max="2"
                step="0.1"
                defaultValue="1"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={12} md={3}>
              Rotate
            </Label>

            <Col>
              <Row>
                <Col>
                  <BaseButton
                    color="primary"
                    outline
                    block
                    onClick={handleRotateLeft}
                    {...leftBtnProps}
                  >
                    <IconFA name="rotate-left" /> Left
                  </BaseButton>
                </Col>
                <Col>
                  <BaseButton
                    color="primary"
                    outline
                    block
                    onClick={handleRotateRight}
                    {...rightBtnProps}
                  >
                    <IconFA name="rotate-right" /> Right
                  </BaseButton>
                </Col>
              </Row>
            </Col>
          </FormGroup>

          <BaseButton
            size="lg"
            color="primary"
            block
            onClick={handleSave}
            disabled={!image}
            {...saveBtnProps}
          >
            {isSubmitting ? 'Saving...' : 'Save changes'}
          </BaseButton>
        </fieldset>
      </Col>
    </Row>
  );
}

export default Component;
