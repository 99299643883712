import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Button} from 'reactstrap';
import FormHeader from './FormHeader';
import FormBase from './FormBase';

export default class FormPost extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    submit: PropTypes.bool,
  };

  static defaultProps = {
    title: true,
    submit: true,
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async (data) => {
    this.props.onSubmit(data);
  };

  get header() {
    const {header, title} = this.props;

    if (!header) return null;

    return <FormHeader title={title} />;
  }

  render() {
    const {
      header,
      title,
      submit,
      isSubmitting,
      description,
      children,
      ...rest
    } = this.props;

    return (
      <FormBase onSubmit={this.handleSubmit} {...rest}>
        {this.header}

        <fieldset disabled={isSubmitting}>{children}</fieldset>

        {submit && (
          <React.Fragment>
            <hr />

            {description && <p className="text-muted">{description}</p>}

            <FormGroup>
              <Button
                type="submit"
                color="primary"
                size="lg"
                className="mr-2"
                block
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </FormGroup>
          </React.Fragment>
        )}
      </FormBase>
    );
  }
}
