import styles from './styles.module.scss';

import React from 'react';
import {Form} from 'reactstrap';
import classNames from 'classnames';

export default class ContentForm extends React.Component {
  static defaultProps = {
    tag: Form,
  };

  render() {
    const {tag, className, children, ...rest} = this.props;

    return React.createElement(
      tag,
      {className: classNames(styles['content-form'], className), ...rest},
      children
    );
  }
}
