import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

class IconFA extends React.Component {
  static defaultProps = {
    fixedWidth: true,
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
  };

  render() {
    const {...rest} = this.props;

    return <FontAwesome {...rest} />;
  }
}

export default IconFA;
