import React, {useState} from 'react';
import {getSchemes} from 'api/common';
import {SearchAsyncBase} from 'components/Forms/FormBases';
import {noop} from 'lodash/util';

const defaultProps = {
  name: 'scheme_id',
  placeholder: 'i.e. Quality Management Systems',
  filters: {},
  onChange: noop,
  isClearable: true,
  cacheOptions: true,
  isDisabled: false,
  getOptionLabel: (option) => option.scheme_name,
  getOptionValue: (option) => option.scheme_id,
};

function SearchSchemeLibrary(props) {
  const {filters, placeholder, onChange, value, ...rest} = props;
  const [selectedOption, setSelectedOption] = useState(value || '');

  async function loadOptions(inputValue) {
    try {
      const {data} = await getSchemes({
        ...filters, // {scheme_type: scheme_type}
        limit: -1,
        search: inputValue,
        order: 'ASC',
        order_by: 'scheme_name',
      });

      return data;
    } catch (error) {
      return [];
    }
  }

  function handleChange(selectedOption) {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  }

  return (
    <SearchAsyncBase
      value={selectedOption}
      loadOptions={loadOptions}
      getOptionLabel={(option) => option.scheme_name}
      getOptionValue={(option) => option.scheme_id}
      openMenuOnClick={true}
      placeholder={placeholder}
      noOptionsMessage={({inputValue}) =>
        inputValue
          ? `There is no result for '${inputValue}'.'`
          : 'Search and select the correct scheme name.'
      }
      onChange={handleChange}
      {...rest}
    />
  );
}

SearchSchemeLibrary.defaultProps = defaultProps;

export default SearchSchemeLibrary;
